<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canCreate"
        variant="primary"
        :to="createCase"
      >
        Add
      </vx-button>
    </vx-page-title-actions>
    <vx-table
      :resource="resource"
      :columns="columns"
      :filters="filters"
    />
  </div>
</template>

<script>
import {
  VxCellBadges,
  VxCellButtons,
  VxCellCaseGenerationTooltip,
  VxCellImageWithTitle,
  VxTable
} from '@/components/table'
import { cases, caseTypes, digitalOceanPath, passDataToResource } from '@/services/resources'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import { VxButton } from '@/components/buttons'
import { path } from '@/router'
import { moneyFormat } from '@/services/utils'
import { sortOrders } from '@/services/table'
import { permissions, useCan } from '@/plugins/acl'
import { casePages, statusButton } from '@/views/cases/casesService'

export default {
  name: 'Cases',
  components: {
    VxTable,
    VxPageTitleActions,
    VxButton
  },
  setup () {
    const resource = passDataToResource(cases.getAll, {
      requestParams: {
        params: { include: 'lastGeneration,chestSections.section,icon' }
      }
    })
    const canCreate = useCan(permissions.chestCreate)
    const createCase = path.createCase

    const actionButtons = ({ id }) => {
      const { pages } = casePages(id)
      return pages.map(({ label, to, icon }) => ({ to, icon, tooltip: label }))
    }

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'title',
        sortable: true,
        component: VxCellImageWithTitle,
        tdAttr: (value, key, item) => {
          const { icon: { path }, title } = item

          return {
            label: title,
            href: digitalOceanPath(path),
            width: 'auto',
            height: '80px'
          }
        }
      },
      {
        key: 'main_price',
        label: 'Price',
        formatter: (value, key, item) => {
          const { type_name, main_price, options } = item
          if (type_name !== caseTypes.brcPriced) return moneyFormat(main_price)

          const { amount, currency } = options.price
          return `${moneyFormat(main_price)} (${moneyFormat({ amount, currency })})`
        }
      },
      {
        key: 'chest_sections',
        label: 'Sections',
        component: VxCellBadges,
        tdAttr: {
          labelKey: 'name'
        },
        formatter: (value) => {
          if (!value) return null

          return value.map(item => {
            return {
              name: item.section.title,
              color: !item.section.is_enabled ? 'dark' : null
            }
          })
        }
      },
      {
        key: 'is_disabled',
        label: 'Status',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: [statusButton(item)]
        })
      },
      {
        key: 'last_active_generation.created_at',
        label: 'Last Active Generation',
        sortable: true,
        component: VxCellCaseGenerationTooltip
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: actionButtons(item)
        })
      }
    ]

    const filters = [
      { key: 'id' },
      { key: 'title' }
    ]

    return {
      columns,
      filters,
      resource,
      canCreate,
      createCase
    }
  }
}
</script>
